<template>
    <div id="app">
        <h1 class="ml3">常用加解密工具</h1>
        <!-- <h1 class="animate__lightSpeedInLeft">fafafsfaf</h1> -->

        <el-divider>⬇️百家姓⬇️</el-divider>
        <el-row>
            <el-col :span="6">
                <el-input v-model="cili" id="cili" placeholder="在此输入磁力链接"></el-input>
            </el-col>
            <el-col :span="6">
                <el-button round @click="zhuanbaijia" :data-clipboard-text=baijia class="baijia">转换为百家姓</el-button>
            </el-col>
            <el-col :span="6">
                <el-input v-model="baijia" id='baijia' placeholder="在此输入百家姓"></el-input>
            </el-col>
            <el-col :span="6">
                <el-button round @click="zhuancili" :data-clipboard-text=cili class="cili">转换为磁力链接</el-button>
            </el-col>
        </el-row>
        

        <el-divider>⬇️价值观⬇️</el-divider>
        <el-row>
            <el-col :span="6">
                <el-input id="decodedarea" :rows="4" placeholder="在此输入磁力链接" v-model="decodedarea">
                </el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="encodebtn" :data-clipboard-text=encodedarea @click="encodebtn">转换为价值观
                </el-button>
            </el-col>
            <el-col :span="6">
                <el-input id="encodedarea" :rows="4" placeholder="在此输入社会主义价值观" v-model="encodedarea">
                </el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="decodebtn" :data-clipboard-text=decodedarea @click="decodebtn">
                    转换为磁力链接</el-button>
            </el-col>

        </el-row>


        <!-- <el-divider>⬇️佛曰⬇️</el-divider>
        <el-row>
            <el-col :span="6">
                <el-input id="orignalMsg" placeholder="在此输入磁力链接" rows="5" v-model="encode"></el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="Btn" @click="tudouEncode" id="tudouEncode" value="听佛说宇宙的真谛">转换为佛说</el-button>
            </el-col>
            <el-col :span="6">
                <el-input id="afterMsg" placeholder="在此输入佛曰（转换前需加上‘佛曰:’）" rows="5" v-model="decode"></el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="Btn" @click="tudouDecode" id="tudouDecode" value="参悟佛所言的真意">转换为磁力链接</el-button>
            </el-col>
        </el-row> -->


        <el-divider>⬇️喵语言⬇️</el-divider>
        <el-row>
            <el-col :span="6">
                <el-input id="miaocili" :rows="4" placeholder="在此输入磁力链接" v-model="miaocili">
                </el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="miaoencode" :data-clipboard-text=miao @click="miaoencode">转换为喵语言
                </el-button>
            </el-col>
            <el-col :span="6">
                <el-input id="miao" :rows="4" placeholder="在此输入喵语言" v-model="miao">
                </el-input>
            </el-col>
            <el-col :span="6">
                <el-button round class="miaodecode" :data-clipboard-text=miaocili @click="miaodecode">
                    转换为磁力链接</el-button>
            </el-col>
        </el-row>

        <el-divider content-position="left">以下是其他不常用的加解密工具链接</el-divider>
        <div>
            <el-link href="http://hi.pcmoe.net/index.html" target="_blank">与熊论道</el-link>
            <el-link href="https://www.keyfc.net/bbs/tools/tudoucode.aspx" type="info">旧与佛论禅</el-link>
            <el-link href="http://hi.pcmoe.net/buddha.html" type="primary">新约佛论禅</el-link>
            <el-link href="http://hi.pcmoe.net/roar.html" type="success">兽音译者</el-link>
            <el-link href="http://hi.pcmoe.net/kaomoji.html" type="warning">颜文字加密</el-link>
            <el-link href="http://hi.pcmoe.net/url.html" type="danger">转链 (迅雷/旋风/快车/飞速)</el-link>
            <el-link href="https://c.p2hp.com/yinxietu/" type="success">图片隐写术加密、图片隐写术解密</el-link>
            <el-link href="https://www.qqxiuzi.cn/bianma/wenbenjiami.php" type="primary">文本加密为汉字</el-link>
        </div>
    </div>
</template>
<script>
import ClipboardJS from 'clipboard'
import 'babel-polyfill'
// import axios from "axios";
import Miao from 'miao-lang'
import anime from 'animejs'
export default {
    data() {
        return {
            head: 'magnet:?xt=urn:btih:',
            cili: '',
            baijia: '',
            obja: {
                "赵": "0", "钱": "1", "孙": "2", "李": "3", "周": "4", "吴": "5", "郑": "6", "王": "7", "冯": "8", "陈": "9",
                "褚": "a", "卫": "b", "蒋": "c", "沈": "d", "韩": "e", "杨": "f", "朱": "g", "秦": "h", "尤": "i", "许": "j",
                "何": "k", "吕": "l", "施": "m", "张": "n", "孔": "o", "曹": "p", "严": "q", "华": "r", "金": "s", "魏": "t",
                "陶": "u", "姜": "v", "戚": "w", "谢": "x", "邹": "y", "喻": "z", "福": "A", "水": "B", "窦": "C", "章": "D",
                "云": "E", "苏": "F", "潘": "G", "葛": "H", "奚": "I", "范": "J", "彭": "K", "郎": "L", "鲁": "M", "韦": "N",
                "昌": "O", "马": "P", "苗": "Q", "凤": "R", "花": "S", "方": "T", "俞": "U", "任": "V", "袁": "W", "柳": "X",
                "唐": "Y", "罗": "Z", "薛": ".", "伍": "-", "余": "_", "米": "+", "贝": "=", "姚": "/", "孟": "?", "顾": "#",
                "尹": "%", "江": "&", "钟": "*"
            },
            decodedarea: '',
            encodedarea: '',
            values: '富强民主文明和谐自由平等公正法治爱国敬业诚信友善',
            foyu: ["命由己造，相由心生", "心不动，万物皆不动", "心不变，万物皆不变", "坐亦禅，行亦禅", "一花一世界，一叶一如来", "春来花自青，秋至叶飘零", "无穷般若心自在，语默动静体自然", "悠然，随心，随性，随缘", "刹那便是永恒", "无悲无喜无梦无幻，无爱无恨四大皆空", "不能了，不能悟，不能舍，不能弃", "参不透，舍不得 ", "不可说,不可说,一说即是错", "一念愚即般若绝，一念智即般若生 ", "菩提本无树，明镜亦非台", "本来无一物，何处惹尘埃", "一即一切，一切即一", "刹那便是永恒", "种如是因，收如是果，一切唯心造", "人无善恶，善恶存乎尔心"],
            encode: '',
            decode: '',
            miao: '',
            miaocili: ''

        }
    },
    methods: {
        // 百家姓转换
        zhuancili() {
            let baijia = this.baijia.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
            let baijiac = baijia.split("")
            var c = ''
            for (let i = 0; i < baijiac.length; i++) {
                let o = this.cy(this.obja, baijiac[i])
                c += o
            }
            c = this.head + c
            this.cili = c

            var clipboard = new ClipboardJS('.cili')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换磁力成功,🧲已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })
        },
        zhuanbaijia() {
            let cili = this.cili.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
            let v = cili.replace(/magnet:\?xt=urn:btih:/, "");
            var strc = v.split("");
            var a = '';
            for (var i = 0; i < strc.length; i++) {
                a += this.ay(this.obja, strc[i]);
            }
            this.baijia = a

            var clipboard = new ClipboardJS('.baijia')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换百家成功,百家姓已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })

        },
        cy(array, val) {
            for (var key in array) {
                if (key == val) {
                    return array[key];
                }
            }
            return '';
        },
        ay(array, val) {
            for (var key in array) {
                if (array[key] == val) {
                    return key;
                }
            }
            return '';
        },


        //价值观转换
        assert(...express) {
            const l = express.length;
            const msg = (typeof express[l - 1] === 'string') ? express[l - 1] : 'Assert Error';
            for (let b of express) {
                if (!b) {
                    throw new Error(msg);
                }
            }
        },
        randBin() {
            return Math.random() >= 0.5;
        },
        str2utf8(str) {
            // return in hex

            const notEncoded = /[A-Za-z0-9-\\_\\.\\!\\~\\*\\'\\(\\)]/g;
            const str1 = str.replace(notEncoded, c => c.codePointAt(0).toString(16));
            let str2 = encodeURIComponent(str1);
            const concated = str2.replace(/%/g, '').toUpperCase();
            return concated;
        },
        utf82str(utfs) {
            this.assert(typeof utfs === 'string', 'utfs Error');

            const l = utfs.length;

            this.assert((l & 1) === 0);

            const splited = [];

            for (let i = 0; i < l; i++) {
                if ((i & 1) === 0) {
                    splited.push('%');
                }
                splited.push(utfs[i]);
            }

            return decodeURIComponent(splited.join(''));
        },
        hex2duo(hexs) {
            // duodecimal in array of number

            // '0'.. '9' -> 0.. 9
            // 'A'.. 'F' -> 10, c - 10    a2fFlag = 10
            //          or 11, c - 6      a2fFlag = 11
            this.assert(typeof hexs === 'string')

            const duo = [];

            for (let c of hexs) {
                const n = Number.parseInt(c, 16);
                if (n < 10) {
                    duo.push(n);
                } else {
                    if (this.randBin()) {
                        duo.push(10);
                        duo.push(n - 10);
                    } else {
                        duo.push(11);
                        duo.push(n - 6);
                    }
                }
            }
            return duo;
        },
        duo2hex(duo) {
            this.assert(duo instanceof Array);

            const hex = [];

            const l = duo.length;

            let i = 0;

            while (i < l) {
                if (duo[i] < 10) {
                    hex.push(duo[i]);
                } else {
                    if (duo[i] === 10) {
                        i++;
                        hex.push(duo[i] + 10);
                    } else {
                        i++;
                        hex.push(duo[i] + 6);
                    }
                }
                i++;
            }
            return hex.map(v => v.toString(16).toUpperCase()).join('');
        },
        duo2values(duo) {
            return duo.map(d => this.values[2 * d] + this.values[2 * d + 1]).join('');
        },
        valuesDecode(encoded) {
            const duo = [];
            for (let c of encoded) {
                const i = this.values.indexOf(c);
                if (i === -1) {
                    continue;
                } else if (i & 1) {
                    continue;
                } else {
                    // i is even
                    duo.push(i >> 1);
                }
            }
            const hexs = this.duo2hex(duo);
            this.assert((hexs.length & 1) === 0);
            let str;
            str = this.utf82str(hexs);
            return str;
        },
        valuesEncode(str) {
            return this.duo2values(this.hex2duo(this.str2utf8(str)));
        },

        encodebtn() {
            this.encodedarea = ''
            const encoded = this.valuesEncode(this.decodedarea)
            this.encodedarea = encoded

            var clipboard = new ClipboardJS('.encodebtn')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换价值观成功,社会主义核心价值观已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })
        },
        decodebtn() {
            this.decodedarea = ''
            const decoded = this.valuesDecode(this.encodedarea)
            this.decodedarea = decoded

            var clipboard = new ClipboardJS('.decodebtn')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换百家成功,🧲已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })
        },

        //佛曰转换
        // tudouEncode() {
        //     // const options = {
        //     //     method: 'POST',
        //     //     url: 'https://www.keyfc.net/bbs/tools/tudou.aspx',
        //     //     headers: {

        //     //         Accept: '*/*',

        //     // //     }
        //     // };

        //     // axios.request(options).then(function (response) {
        //     //     console.log(response.data);
        //     // }).catch(function (error) {
        //     //     console.error(error);
        //     // });


        //     //
        // },


        //喵语言转换
        miaoencode() {
            let cili = this.miaocili
            let miao = Miao.encode(cili)
            this.miao = miao

            var clipboard = new ClipboardJS('.miaoencode')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换喵语言成功,喵语言已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })
        },
        miaodecode() {
            let miao = this.miao
            let cili = Miao.decode(miao)
            this.miaocili = cili

            var clipboard = new ClipboardJS('.miaodecode')
            clipboard.on('success', () => {
                this.$notify({
                    title: '成功',
                    message: '转换磁力成功,🧲已复制到剪贴板',
                    type: 'success'
                });
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', () => {
                // 不支持复制
                console.log('该浏览器不支持自动复制')
                // 释放内存
                clipboard.destroy()
            })
        },

        biaoti() {
            // Wrap every letter in a span
            var textWrapper = document.querySelector('.ml3');
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

            anime.timeline({ loop: true })
                .add({
                    targets: '.ml3 .letter',
                    opacity: [0, 1],
                    easing: "easeInOutQuad",
                    duration: 2250,
                    delay: (el, i) => 150 * (i + 1)
                }).add({
                    targets: '.ml3',
                    opacity: 0,
                    duration: 1000,
                    easing: "easeOutExpo",
                    delay: 1000
                });
        }
        //
        
    },
    mounted() {
        this.biaoti()
    }
}
</script>
<style  scoped>

.buttons {
    text-align: center;
    align-items: center;
}

.ml3 {
    font-weight: 900;
    font-size: 3.5em;
}

.el-link {
    margin-right: 8px;
  }
  .el-link .el-icon--right.el-icon {
    vertical-align: text-bottom;
  }

</style>
